<template>
  <div class="thankyou">
    <div class="content">
      <img :src="logoSource" class="logo" />
    </div>
    <div>
      <h2 class="thank-you-text custom-font">Thank You !</h2>
    </div>
    <div class="message">
      <div class="image-cart">
        <img :src="imageSource" class="cart" />
      </div>
      <div>
        <h4 class="message-text">You have successfully made the purchase.</h4>
        <h4 class="message-text">We hope you enjoy the journey with us.</h4>
      </div>
    </div>
    <div class="action">
      <v-btn
        color="#0099dc"
        class="white--text rounded-lg button-dim pa-2"
        depressed
        :height="btnDim.height"
        :width="btnDim.width"
        @click="$router.push('/')"
      >
        <span class="custom-font button-text"
          >Take Me to Mouchak Classroom</span
        >
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "ThankYouPage",
  data() {
    return {
      logoSource: require("root-src/assets/mouchak_favicon.svg"),
      imageSource: require("#ecf/thankyou/assets/cart_icon.png")
    };
  },
  computed: {
    screenSize() {
      return this.$vuetify.breakpoint.width;
    },
    imgDim() {
      let ss = this.screenSize;
      return { height: 0.22 * ss, width: 0.42 * ss };
    },
    btnDim() {
      let ss = this.screenSize;
      let width = ss < 601 ? 200 : ss < 901 ? 250 : ss < 1601 ? 300 : 400;
      let height = ss < 601 ? 30 : ss < 901 ? 40 : ss < 1601 ? 50 : 55;
      return { height: height, width: width };
    }
  }
};
</script>

<style scoped lang="scss">
.thankyou {
  //   background-color: white;
  height: 90vh;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.custom-font {
  font-family: roboto;
}

.thank-you-text {
  color: #0099dc;
  font-weight: 500;
  @media only screen and (min-width: 1601px) {
    font-size: 50px;
  }
  @media only screen and (min-width: 901px) and (max-width: 1600px) {
    font-size: 40px;
  }
  @media only screen and (min-width: 600px) and (max-width: 900px) {
    font-size: 32px;
  }
  @media only screen and (max-width: 599px) {
    font-size: 24px;
  }
}

.logo {
  @media only screen and (min-width: 1601px) {
    height: 130px;
    width: 130px;
  }
  @media only screen and (min-width: 901px) and (max-width: 1600px) {
    height: 80px;
    width: 80px;
  }
  @media only screen and (min-width: 600px) and (max-width: 900px) {
    height: 60px;
    width: 60px;
  }
  @media only screen and (max-width: 599px) {
    height: 42px;
    width: 42px;
  }
}

.image-cart {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart {
  @media only screen and (min-width: 1601px) {
    height: 60px;
    width: 60px;
  }
  @media only screen and (min-width: 901px) and (max-width: 1600px) {
    height: 50px;
    width: 50px;
  }
  @media only screen and (min-width: 600px) and (max-width: 900px) {
    height: 40px;
    width: 40px;
  }
  @media only screen and (max-width: 599px) {
    height: 32px;
    width: 32px;
  }
}

.message {
  display: grid;
  grid-template-columns: 1fr 6fr;

  @media only screen and (min-width: 1601px) {
    padding-top: 30px;
  }
  @media only screen and (min-width: 901px) and (max-width: 1600px) {
    padding-top: 20px;
  }
  @media only screen and (min-width: 600px) and (max-width: 900px) {
    padding-top: 15px;
  }
  @media only screen and (max-width: 599px) {
    padding-top: 10px;
  }
}

.message-text {
  color: #202020c0;
  font-stretch: expanded;
  @media only screen and (min-width: 1601px) {
    font-size: 24px;
  }
  @media only screen and (min-width: 901px) and (max-width: 1600px) {
    font-size: 18px;
  }
  @media only screen and (min-width: 600px) and (max-width: 900px) {
    font-size: 14px;
  }
  @media only screen and (max-width: 599px) {
    font-size: 12px;
  }
}

.action {
  @media only screen and (min-width: 1601px) {
    padding-top: 40px;
  }
  @media only screen and (min-width: 901px) and (max-width: 1600px) {
    padding-top: 30px;
  }
  @media only screen and (min-width: 600px) and (max-width: 900px) {
    padding-top: 50px;
  }
  @media only screen and (max-width: 599px) {
    padding-top: 80px;
  }
}

.button-text {
  font-weight: 600;
  @media only screen and (min-width: 1601px) {
    font-size: 18px;
  }
  @media only screen and (min-width: 901px) and (max-width: 1600px) {
    font-size: 14px;
  }
  @media only screen and (min-width: 600px) and (max-width: 900px) {
    font-size: 11px;
  }
  @media only screen and (max-width: 599px) {
    font-size: 10px;
  }
}
</style>
