var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "thankyou" }, [
    _c("div", { staticClass: "content" }, [
      _c("img", { staticClass: "logo", attrs: { src: _vm.logoSource } }),
    ]),
    _vm._m(0),
    _c("div", { staticClass: "message" }, [
      _c("div", { staticClass: "image-cart" }, [
        _c("img", { staticClass: "cart", attrs: { src: _vm.imageSource } }),
      ]),
      _vm._m(1),
    ]),
    _c(
      "div",
      { staticClass: "action" },
      [
        _c(
          "v-btn",
          {
            staticClass: "white--text rounded-lg button-dim pa-2",
            attrs: {
              color: "#0099dc",
              depressed: "",
              height: _vm.btnDim.height,
              width: _vm.btnDim.width,
            },
            on: {
              click: function ($event) {
                return _vm.$router.push("/")
              },
            },
          },
          [
            _c("span", { staticClass: "custom-font button-text" }, [
              _vm._v("Take Me to Mouchak Classroom"),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("h2", { staticClass: "thank-you-text custom-font" }, [
        _vm._v("Thank You !"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("h4", { staticClass: "message-text" }, [
        _vm._v("You have successfully made the purchase."),
      ]),
      _c("h4", { staticClass: "message-text" }, [
        _vm._v("We hope you enjoy the journey with us."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }